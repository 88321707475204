













































































































import {debounce} from 'vue-debounce';
import {Component, Vue} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';

import {PRODUCT_TYPE} from '../constants';
import {Picto, Product, Zone} from '../interfaces';
import ModalFormule from './ModalFormule.vue';
import ModalProductDetail from './ModalProduct.vue';
import ProductCard from './ProductCard.vue';

@Component({
  components: {
    ModalProductDetail,
    ProductCard,
    ModalFormule,
  },
})
export default class ListCards extends Vue {
  @Action('updateSelectedProductTypeName', {namespace: 'menu'}) updateSelectedProductTypeName!: (
    id: string
  ) => void;

  @Getter('loadingMenu', {namespace: 'menu'}) loadingMenu!: boolean;

  @Getter('error', {namespace: 'menu'}) error!: boolean;

  @Getter('products', {namespace: 'menu'}) products!: Product[];

  @Getter('formulaZones', {namespace: 'menu'}) formulaZones!: Zone[];

  @Getter('formulaProducts', {namespace: 'menu'}) formulaProducts!: (id: string) => Product[];

  @Getter('currentProductTypes', {namespace: 'menu'}) currentProductTypes!: Picto[];

  typeProduct = PRODUCT_TYPE;

  created(): void {
    document.addEventListener('scroll', this.handleScroll);
  }

  destroyed(): void {
    document.removeEventListener('scroll', this.handleScroll);
  }

  productsTypes(typeProduct: PRODUCT_TYPE | string): Product[] {
    return this.products.filter((product) => product.type === typeProduct);
  }

  hasProductsFormulas(): boolean {
    return this.formulaZones.some((formula) => this.formulaProducts(formula.code).length);
  }

  handleScroll(): void {
    debounce(() => {
      const element = document.getElementById(this.currentProductTypes[0].name);
      if (element && window.scrollY >= element.offsetTop) {
        const productTypes = this.currentProductTypes.map((productType) => ({
          name: productType.name,
          position: document.getElementById(productType.name)?.offsetTop || 0,
        }));
        const selectedProductType = productTypes.reduce((a, b) =>
          a.position <= window.scrollY && b.position > window.scrollY ? a : b
        );
        this.updateSelectedProductTypeName(selectedProductType.name);
      } else {
        this.updateSelectedProductTypeName('');
      }
    }, '1000ms')();
  }
}
