





















import type {Container, Main} from 'tsparticles';
import {loadConfettiPreset} from 'tsparticles-preset-confetti';
import {Component, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import {ConfettiOptions} from '../constants';

@Component
export default class LogoPostIt extends Vue {
  @Getter('name', {namespace: 'site'}) name!: string;

  options = ConfettiOptions;

  container: Container | null = null;

  countClick = 0;

  destroyed(): void {
    if (this.container) this.container.destroy();
  }

  particlesInit(main: Main): void {
    loadConfettiPreset(main);
  }

  particlesLoaded(container: Container): void {
    this.container = container;
  }
}
