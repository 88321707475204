
import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';

import SelectorSite from '@/site/components/SelectorSite.vue';
import {Links, ServiceLinks} from '@/site/interfaces';

@Component({components: {SelectorSite}})
export default class BaseService extends Vue {
  @Getter('links', {namespace: 'site'}) links!: Links;

  @Getter('hasArticles', {namespace: 'article'}) hasArticles!: boolean;

  @Getter('isOpenSelector', {namespace: 'site'}) isOpenSelector!: boolean;

  @Getter('isDemo', {namespace: 'site'}) isDemo!: boolean;

  @Getter('enableAffluence', {namespace: 'site'}) enableAffluence!: boolean;

  // @Getter('isBookingActivated', {namespace: 'site'}) isBookingActivated!: boolean;

  // @Getter('enableContact', {namespace: 'site'}) enableContact!: boolean;
  @Action('updateOpenSelector', {namespace: 'site'}) updateOpenSelector!: (
    isOpen: boolean
  ) => void;

  services: ServiceLinks[] = [];

  onlineServices: ServiceLinks[] = [];

  menuDescription = 'Consulter la carte du restaurant';

  newsDescription = 'Voir les actus de la FJM';

  accountDescription = 'Accéder à votre compte personnel';

  dialog = false;

  @Watch('$store.state.site.links')
  setLinks(): void {
    if (this.links) {
      this.onlineServices = [
        {
          title: 'Compte',
          name: 'badge',
          link: this.links.badgeUrl || null,
          show: !!this.links.badgeUrl,
          description: this.accountDescription,
        },
        // {
        //   title: 'Room Service',
        //   link: this.links.roomServiceUrl || null,
        //   show: !!this.links.roomServiceUrl,
        // },
        {
          title: 'À emporter',
          name: 'clickAndCollect',
          link: this.links.clickAndCollectUrl || null,
          show: !!this.links.clickAndCollectUrl,
        },
      ].filter((service) => service.show);
    }
  }

  @Watch('$store.state.article.articles')
  showLocalService(): void {
    this.setServices();
  }

  mounted(): void {
    this.setServices();
    this.setLinks();
  }

  setServices(): void {
    if (this.hasArticles) {
      this.services = [
        {
          title: 'Menus',
          name: 'menu',
          show: true,
          description: this.menuDescription,
        },
        {
          title: 'Actualités',
          name: 'articles',
          show: this.hasArticles,
          description: this.newsDescription,
        },
        // {
        //   title: 'Réservation',
        //   name: 'booking',
        //   show: this.isBookingActivated,
        // },
        // {
        //   title: 'Contact',
        //   name: 'contact',
        //   show: this.enableContact,
        // },
      ].filter((service) => service.show);
    } else {
      this.services = [
        {
          title: 'Menus',
          name: 'menu',
          show: true,
          description: this.menuDescription,
        },
        // {
        //   title: 'Réservation',
        //   name: 'booking',
        //   show: this.isBookingActivated,
        // },
        // {
        //   title: 'Contact',
        //   name: 'contact',
        //   show: this.enableContact,
        // },
      ].filter((service) => service.show);
    }
  }

  selectService(service: {name: string; params: {id: string}}): void {
    this.$router.push(service);
  }

  onClickOutside(): void {
    this.updateOpenSelector(false);
  }
}
