import { render, staticRenderFns } from "./FilterDay.vue?vue&type=template&id=118f67a7&scoped=true&"
import script from "./FilterDay.vue?vue&type=script&lang=ts&"
export * from "./FilterDay.vue?vue&type=script&lang=ts&"
import style0 from "./FilterDay.vue?vue&type=style&index=0&id=118f67a7&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "118f67a7",
  null
  
)

export default component.exports