













import {Component, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import NoData from '@/core/components/NoData.vue';
import LogoPostIt from '@/site/components/LogoPostIt.vue';

import FilterDay from '../components/FilterDay.vue';
import ListCards from '../components/ListCards.vue';
import SelectProductType from '../components/SelectProductType.vue';
import {Day} from '../interfaces';

@Component({
  components: {
    FilterDay,
    ListCards,
    NoData,
    SelectProductType,
    LogoPostIt,
  },
})
export default class Menu extends Vue {
  @Getter('days', {namespace: 'menu'}) days!: Day[];

  @Getter('hasArticles', {namespace: 'article'}) hasArticles!: boolean;

  @Getter('name', {namespace: 'site'}) siteName!: string;
}
