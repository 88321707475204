




















































import type {Container, Main} from 'tsparticles';
import {loadConfettiPreset} from 'tsparticles-preset-confetti';
import {Component, Vue} from 'vue-property-decorator';

import {ConfettiOptions} from '@/site/constants';

@Component
export default class Wait extends Vue {
  year = new Date().getFullYear();

  now = new Date().getTime();

  countDownDate = new Date('Mar 14, 2022 06:30:00').getTime();

  days = 0;

  hours = 0;

  minutes = 0;

  seconds = 0;

  container: Container | null = null;

  options = ConfettiOptions;

  mounted(): void {
    this.setDate();
    this.countDown();
  }

  destroyed(): void {
    if (this.container) this.container.destroy();
  }

  particlesInit(main: Main): void {
    loadConfettiPreset(main);
  }

  particlesLoaded(container: Container): void {
    this.container = container;
  }

  countDown(): void {
    const x = setInterval(() => {
      if (this.setDate() < 0) {
        clearInterval(x);
        this.days = 0;
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;
      }
    }, 1000);
  }

  setDate(): number {
    const now = new Date().getTime();

    const distance = this.countDownDate - now;

    this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
    this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
    return distance;
  }

  // @Getter('enableContact', {namespace: 'site'}) enableContact!: boolean;

  // @Getter('isBookingActivated', {namespace: 'site'}) isBookingActivated!: boolean;
}
