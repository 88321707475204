import {formatShortDate, isToday} from '@/core';

import {Day, Menu, MenuState, Picto, Service, Zone} from '../interfaces';
import {
  hasMenus,
  isBeforeService,
  isNextDayService,
  isNextServiceAvailable,
  reduceDays,
} from '../tools';

export const mutations = {
  SET_CURRENT_MENU(state: MenuState, menu: Menu): void {
    state.currentMenu = menu;
    if (menu.serviceCode !== state.selectedService?.code) {
      const foundService = state.services.find((service) => service.code === menu.serviceCode);
      mutations.SET_SERVICE(state, foundService);
    }
  },
  SET_CURRENT_PRODUCT_TYPES(state: MenuState, currentProductTypes: Picto[]): void {
    state.currentProductTypes = currentProductTypes;
  },
  SET_DAY(state: MenuState, selectedDay?: Day): void {
    if (selectedDay) {
      state.selectedDay = selectedDay;
      if (selectedDay.isFake) {
        mutations.SET_CURRENT_PRODUCT_TYPES(state, []);
      }
    } else if (state.days.length) {
      state.selectedDay = {
        date: state.days[0].date,
        formattedShortDate: formatShortDate(state.days[0].date),
      };
    }
    if (state.selectedDay) mutations.SET_SERVICE(state);
  },
  SET_DAYS(state: MenuState): void {
    state.days = reduceDays(state.menus);
  },
  SET_ERROR(state: MenuState, error: boolean): void {
    state.error = error;
  },
  SET_LOADING_MENU(state: MenuState, loading: boolean): void {
    state.loading = loading;
  },
  SET_MENUS(state: MenuState, menus: Menu[]): void {
    state.menus = menus;
  },
  SET_SELECTED_PRODUCT_TYPE_NAME(state: MenuState, selectedProductTypeName: string): void {
    state.selectedProductTypeName = selectedProductTypeName;
  },
  SET_SERVICE(state: MenuState, selectedService?: Service): void {
    if (selectedService) {
      state.selectedService = selectedService;
    } else if (
      state.selectedDay &&
      isToday(state.selectedDay.date) &&
      isNextServiceAvailable(state.services, state.menus, state.selectedDay)
    ) {
      // selectionne le service(avec des produits) le plus proche de la journée
      const service = state.services.reduce((a, b) =>
        isBeforeService(a.startAt, a.endAt, b.startAt) &&
        hasMenus(state.menus, state.selectedDay, a)
          ? a
          : b
      );
      state.selectedService = service;
    } else if (
      !state.selectedService ||
      (state.selectedDay &&
        state.selectedService &&
        !hasMenus(state.menus, state.selectedDay, state.selectedService))
    ) {
      const service = state.services.reduce((a, b) =>
        isNextDayService(a.startAt, b.startAt) && hasMenus(state.menus, state.selectedDay, a)
          ? a
          : b
      );
      state.selectedService = service;
    }
  },
  SET_SERVICES(state: MenuState, services: Service[]): void {
    state.services = services;
  },
  SET_ZONES(state: MenuState, zones: Zone[]): void {
    state.zones = zones;
  },
};
