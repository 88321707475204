import {AffluenceResponse, AffluenceZone, Links, Site, SiteState} from '../interfaces';

export const mutations = {
  SET_AFFLUENCE_RESPONSE(state: SiteState, affluenceResponse: AffluenceResponse[]): void {
    state.affluenceResponse = affluenceResponse;
  },
  SET_AFFLUENCE_ZONES(state: SiteState, affluenceZones: AffluenceZone[]): void {
    state.affluenceZones = affluenceZones;
  },
  SET_CUSTOMER_ID(state: SiteState, customerId: string): void {
    state.customerId = customerId;
  },
  SET_ENABLE_CONTACT(state: SiteState, enableContact: boolean): void {
    state.enableContact = enableContact;
  },
  SET_ENABLE_AFFLUENCE(state: SiteState, enableAffluence: boolean): void {
    state.enableAffluence = enableAffluence;
  },
  SET_ENABLE_FEEDBACK(state: SiteState, enableFeedback: boolean): void {
    state.enableFeedback = enableFeedback;
  },
  SET_ERROR(state: SiteState, error: boolean): void {
    state.error = error;
  },
  SET_IS_BOOKING_ACTIVATED(state: SiteState, isBookingActivated: boolean): void {
    state.isBookingActivated = isBookingActivated;
  },
  SET_IS_DEMO(state: SiteState, isDemo: boolean): void {
    state.isDemo = isDemo;
  },
  SET_IS_OPEN_SELECTOR(state: SiteState, isOpen: boolean): void {
    state.isOpenSelector = isOpen;
  },
  SET_LINKS(state: SiteState, links: Links): void {
    state.links = links;
  },
  SET_LOADING(state: SiteState, loading: boolean): void {
    state.loading = loading;
  },
  SET_MOTD(state: SiteState, motd: string | null): void {
    state.motd = motd;
  },
  SET_NAME(state: SiteState, name: string): void {
    state.name = name;
  },
  SET_SLUG(state: SiteState, slug: string): void {
    state.slug = slug;
  },
  SET_SITE_LIST(state: SiteState, siteList: Site[]): void {
    state.siteList = siteList;
  },
};
