import spacetime from 'spacetime';

import {AffluenceResponse} from '@/site/interfaces';

import {tzParis} from './date.tools';

export function setLabel(m: string): string {
  const {isDST} = spacetime().timezone().current;

  let date = spacetime(m).goto(tzParis);
  date = date.hour(date.hour() + (isDST ? 0 : 1));

  return `${date.format('{hour-24-pad}')}:${date.format('{minute-pad}')}`;
}

export function setLabels(affluenceResponse: AffluenceResponse[]): string[] {
  return affluenceResponse.map((response, index) => (!(index % 2) ? setLabel(response.start) : ''));
}

export function isCurrentStep(start: Date, end: Date): boolean {
  const {isDST} = spacetime().timezone().current;

  let sStart = spacetime(start).goto(tzParis);
  sStart = sStart.hour(sStart.hour() + (isDST ? 0 : 1));

  let sEnd = spacetime(end).goto(tzParis);
  sEnd = sEnd.hour(sEnd.hour() + (isDST ? 0 : 1));

  const now = spacetime.now().goto(tzParis);
  return now.isAfter(sStart) && now.isBefore(sEnd);
}

export function setPeople(affluenceResponse: AffluenceResponse[]): (number | null)[] {
  return affluenceResponse.map((response) =>
    isCurrentStep(new Date(response.start), new Date(response.end)) && response.peopleIn
      ? Math.round(response.peopleIn) || null
      : Math.round(response.avgIn) || null
  );
}
