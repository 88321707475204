



























































import {Component, Vue} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';

import {isEqualDate} from '@/core/tools';

import {Day, Menu, Service} from '../interfaces';
import {getMenuId} from '../tools';

@Component
export default class FilterDay extends Vue {
  @Getter('days', {namespace: 'menu'}) days!: Day[];

  @Getter('day', {namespace: 'menu'}) storedDay!: Day;

  @Getter('menus', {namespace: 'menu'}) menus!: Menu[];

  // @Getter('services', {namespace: 'menu'}) services!: Service[];

  @Getter('service', {namespace: 'menu'}) storedService!: Service;

  @Getter('error', {namespace: 'menu'}) error!: boolean;

  @Action('updateDay', {namespace: 'menu'}) updateDay!: (day: Day) => void;

  @Action('updateService', {namespace: 'menu'}) updateService!: (service: Service) => void;

  @Action('getCurrentMenu', {namespace: 'menu'}) getCurrentMenu!: (id: string) => void;

  @Action('resetError', {namespace: 'menu'}) resetError!: () => void;

  // selectedService(value: Service): void {
  //   this.updateService(value);
  //   if (this.error) this.resetError();
  //   this.getCurrentMenu(getMenuId(this.menus, this.storedDay, this.storedService));
  // }

  selectedDay(value: Day): void {
    this.updateDay(value);
    if (this.error) this.resetError();
    const menuId = getMenuId(this.menus, this.storedDay, this.storedService);
    if (menuId) this.getCurrentMenu(menuId);
  }

  isSameDay(day: Day): boolean {
    return isEqualDate(day.date, this.storedDay.date);
  }
}
