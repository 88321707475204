import spacetime from 'spacetime';

export const DAYS = [
  {weekDay: 1, name: 'lun.'},
  {weekDay: 2, name: 'mar.'},
  {weekDay: 3, name: 'mer.'},
  {weekDay: 4, name: 'jeu.'},
  {weekDay: 5, name: 'ven.'},
  // {weekDay: 6, name: 'samedi'},
  // {weekDay: 0, name: 'dimanche'},
];
export const spacetimeFr = {
  days: {
    long: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    short: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
  },
  months: {
    long: [
      'janvier',
      'février',
      'mars',
      'avril',
      'mai',
      'juin',
      'juillet',
      'août',
      'septembre',
      'octobre',
      'novembre',
      'décembre',
    ],
    short: [
      'jan',
      'fév',
      'mars',
      'avril',
      'mai',
      'juin',
      'juil',
      'août',
      'sept',
      'oct',
      'nov',
      'déc',
    ],
  },
};
export const tzParis = 'Europe/Paris';

export function formatShortDate(date: string): string[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dateSpace: any = spacetime(date, tzParis);
  dateSpace.i18n(spacetimeFr);
  return [dateSpace.format('{date-pad}').toString(), dateSpace.format('{day-short}').toString()];
}
export function formatDate(date: string): string {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dateSpace: any = spacetime(date, tzParis);
  dateSpace.i18n(spacetimeFr);
  return dateSpace.format('{day} {date} {month-short} {year}').toString();
}
export function formatTime(date: string): string {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dateSpace: any = spacetime(date, tzParis).goto(tzParis);
  dateSpace.i18n(spacetimeFr);
  return dateSpace.format('{hour-24-pad}:{minute-pad}').toString();
}
export function isEqualDate(d: string, n: string): boolean {
  return spacetime(d, tzParis).isSame(spacetime(n, tzParis), 'date');
}
export function isToday(n: string): boolean {
  return spacetime.now(tzParis).isSame(spacetime(n, tzParis), 'date');
}
export function isBeforeDate(m: string | number, n: string | number): boolean {
  return spacetime(m, tzParis).isBefore(spacetime(n, tzParis));
}

export function setHoursAndMinutes(hour: string, date: Date): string {
  const {isDST} = spacetime().timezone().current;
  const dateSp = spacetime(date, tzParis)
    .hour(parseInt(hour.split(':')[0], 10) - (isDST ? 0 : 1))
    .minute(parseInt(hour.split(':')[1], 10))
    .second(0)
    .millisecond(0);
  return `${dateSp.format('iso-utc')}`;
}
