import {Nutriscore, Picto} from '../interfaces';
import {PRODUCT_TYPE} from './enums';

export const ALLERGENES: Picto[] = [
  {
    name: 'Arachides',
    id: 'ARA',
    fileName: 'arachides.svg',
  },
  {
    name: 'Celeri',
    id: 'CEL',
    fileName: 'celeri.svg',
  },
  {
    name: 'Crustacés',
    id: 'CRU',
    fileName: 'crustaces.svg',
  },
  {
    name: 'Fruits à coque',
    id: 'FCQ',
    fileName: 'fruits-a-coque.svg',
  },
  {
    name: 'Gluten',
    id: 'GLU',
    fileName: 'gluten.svg',
  },
  {
    name: 'Lait',
    id: 'LAI',
    fileName: 'lait.svg',
  },
  {
    name: 'Lupin',
    id: 'LUP',
    fileName: 'lupin.svg',
  },
  {
    name: 'Mollusques',
    id: 'MOL',
    fileName: 'mollusques.svg',
  },
  {
    name: 'Moutarde',
    id: 'MOU',
    fileName: 'moutarde.svg',
  },
  {
    name: 'Oeuf',
    id: 'OEU',
    fileName: 'oeuf.svg',
  },
  {
    name: 'Poisson',
    id: 'POI',
    fileName: 'poisson.svg',
  },
  {
    name: 'Sésame',
    id: 'SES',
    fileName: 'sesame.svg',
  },
  {
    name: 'Soja',
    id: 'SOJ',
    fileName: 'soja.svg',
  },
  {
    name: 'Sulfite',
    id: 'SUL',
    fileName: 'sulfite.svg',
  },
];

export const ORIGINES: Picto[] = [
  {
    name: 'Bio',
    id: 'BIO',
    fileName: 'ab.svg',
  },
  {
    name: 'Label Rouge',
    id: 'LR',
    fileName: 'lr.svg',
  },
  {
    name: 'AOC',
    id: 'AOC',
    fileName: 'aoc.svg',
  },
  {
    name: 'AOP',
    id: 'AOP',
    fileName: 'aop.svg',
  },
  {
    name: 'IGP',
    id: 'IGP',
    fileName: 'igp.svg',
  },
  {
    name: 'Régions Ultrapériphériques',
    id: 'RUP',
    fileName: 'rup.svg',
  },
  {
    name: 'Fait maison',
    id: 'FM',
    fileName: 'fm.svg',
  },
  {
    name: 'Local',
    id: 'LOC',
    fileName: 'loc.svg',
  },
  {
    name: 'MSC',
    id: 'MSC',
    fileName: 'msc.svg',
  },
  {
    name: 'Pêche durable',
    id: 'PDU',
    fileName: 'pdu.svg',
  },
  {
    name: 'Porc français',
    id: 'POF',
    fileName: 'pof.svg',
  },
  {
    name: 'Volaille française',
    id: 'VLF',
    fileName: 'vlf.svg',
  },
  {
    name: 'Lapin de france',
    id: 'LAF',
    fileName: 'laf.svg',
  },
  {
    name: 'Oeufs de france',
    id: 'OEF',
    fileName: 'oef.svg',
  },
  {
    name: 'Viande de veau française',
    id: 'VVF',
    fileName: 'vvf.svg',
  },
  {
    name: 'Viande chevaline française',
    id: 'VCF',
    fileName: 'vcf.svg',
  },
  {
    name: 'Viande bovine française',
    id: 'VBF',
    fileName: 'vbf.svg',
  },
  {
    name: 'Viande ovine française',
    id: 'VOF',
    fileName: 'vof.svg',
  },
  {
    name: "Viande d'agneau française",
    id: 'VAF',
    fileName: 'vaf.svg',
  },
  {
    name: 'Viande de chèvre française',
    id: 'CHF',
    fileName: 'chf.svg',
  },
  {
    name: 'Viande de chevreau française',
    id: 'CVF',
    fileName: 'cvf.svg',
  },
  {
    name: 'Foie gras de france',
    id: 'FGF',
    fileName: 'fgf.svg',
  },
  {
    name: 'Menu malin',
    id: 'MM',
    fileName: 'mm.svg',
  },
  {
    name: 'Option végé',
    id: 'VEG',
    fileName: 'veg.svg',
  },
  {
    name: 'Spicy',
    id: 'SPI',
    fileName: 'spi.svg',
  },
];

export const PRODUCT_TYPES: Picto[] = [
  {
    name: 'Entrée',
    id: PRODUCT_TYPE.ENT,
    fileName: 'entrees.svg',
  },
  {
    name: 'Plat',
    id: PRODUCT_TYPE.PLA,
    fileName: 'plats.svg',
  },
  {
    name: 'Accompagnement',
    id: PRODUCT_TYPE.ACC,
    fileName: 'accompagnements.svg',
  },
  {
    name: 'Dessert',
    id: PRODUCT_TYPE.DES,
    fileName: 'desserts.svg',
  },
  {
    name: 'Fromage',
    id: PRODUCT_TYPE.FRO,
    fileName: 'fromages.svg',
  },
  {
    name: 'Laitage',
    id: PRODUCT_TYPE.LAI,
    fileName: 'laitages.svg',
  },
  {
    name: 'Boisson',
    id: PRODUCT_TYPE.BOI,
    fileName: 'boissons.svg',
  },
  {
    name: 'Autre',
    id: PRODUCT_TYPE.AUT,
    fileName: 'formules.svg',
  },
  {
    name: 'Formule',
    id: PRODUCT_TYPE.FOR,
    fileName: 'formules.svg',
  },
];

export const NUTRISCORE: Nutriscore[] = [
  {
    id: 1,
    fileName: '1.svg',
    fileNameVertical: 'v_1.svg',
  },
  {
    id: 2,
    fileName: '2.svg',
    fileNameVertical: 'v_2.svg',
  },
  {
    id: 3,
    fileName: '3.svg',
    fileNameVertical: 'v_3.svg',
  },
  {
    id: 4,
    fileName: '4.svg',
    fileNameVertical: 'v_4.svg',
  },
  {
    id: 5,
    fileName: '5.svg',
    fileNameVertical: 'v_5.svg',
  },
];
