import spacetime from 'spacetime';

import {formatShortDate, isBeforeDate, isEqualDate, isToday, tzParis} from '@/core/tools';

import {Day, Menu} from '../interfaces';

export function reduceDays(menus: Menu[]): Day[] {
  const nextSevenDay = new Date();
  nextSevenDay.setDate(nextSevenDay.getDate() + 7);
  const reduceMenus = menus
    .filter((menu) => isBeforeDate(menu.date, nextSevenDay.toISOString()))
    .reduce<Day[]>((days, menu) => {
      if (
        !days.find((day) => isEqualDate(menu.date, day.date)) &&
        (isBeforeDate(spacetime.now(tzParis).toNativeDate().toISOString(), menu.date) ||
          isToday(menu.date))
      ) {
        days.push({date: menu.date, formattedShortDate: formatShortDate(menu.date), isFake: false});
      }
      return days;
    }, [])
    .sort((a, b) => (isBeforeDate(a.date, b.date) ? -1 : 1))
    .slice(0, 5);

  if (reduceMenus.length < 5) {
    const today = new Date();
    const nextWeekDays: Day[] = Array(7)
      .fill('')
      .map((_, index) => {
        const nextWeek = spacetime(today);
        const nextDay = nextWeek.add(index, 'day');
        return {
          date: nextDay.toNativeDate().toISOString(),
          formattedShortDate: formatShortDate(nextDay.toNativeDate().toISOString()),
          isFake: true,
        };
      })
      .filter((currentDate) => {
        const date = new Date(currentDate.date);
        return date.getDay() !== 0 && date.getDay() !== 6;
      });

    return reduceMenus
      .concat(nextWeekDays)
      .reduce<Day[]>((days, menu) => {
        if (
          !days.find((day) => isEqualDate(menu.date, day.date)) &&
          (isBeforeDate(spacetime.now(tzParis).toNativeDate().toISOString(), menu.date) ||
            isToday(menu.date))
        ) {
          days.push({
            date: menu.date,
            formattedShortDate: formatShortDate(menu.date),
            isFake: menu.isFake,
          });
        }
        return days;
      }, [])
      .sort((a, b) => (isBeforeDate(a.date, b.date) ? -1 : 1))
      .slice(0, 5);
  }
  return reduceMenus;
}
