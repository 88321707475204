import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":_vm.$vuetify.breakpoint.smAndDown,"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"max-width":"700px"},on:{"click:outside":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VSheet,{staticClass:"fill-width",attrs:{"outlined":"","color":"white","rounded":""}},[_c(VHover,{attrs:{"close-delay":"30","open-delay":"30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"d-flex justify-space-between flex-column px-3 py-1",class:{'on-hover': hover},attrs:{"rounded":"","height":"100%","elevation":hover ? 4 : 0}},[_c('div',{staticClass:"d-flex flex-column align-center justify-space-between flex-grow-1"},[_c('div',{staticClass:"d-flex justify-space-between fill-width pt-2"},[_c('span',{staticClass:"caption mr-2 font-weight-medium d-flex align-end"},[_c(VIcon,{staticClass:"pr-1",attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-map-marker ")]),_c('span',{staticClass:"dishDetails__location"},[_vm._v(" "+_vm._s(_vm.zone(_vm.product.zoneCode).name))])],1)]),_c('div',{staticClass:"d-flex justify-space-between align-center py-2 pt-4 flex-grow-1 fill-width"},[_c('h6',{staticClass:"pa-0 text-h6 font-weight-bold align-self-start"},[_vm._v(" "+_vm._s(_vm.product.name)+" ")]),_c('div')]),_c('div',{staticClass:"d-flex justify-space-between align-center pt-2 flex-grow-1 fill-width"},[_c('div',{staticClass:"pa-0 d-flex"},_vm._l((_vm.getOrigines(_vm.product).slice(0, 2)),function(origine){return _c(VImg,{key:origine.name,staticClass:"mx-1",attrs:{"left":"","contain":"","src":require('../assets/images/collections/origins/' + origine.fileName),"width":"2.5rem","height":"2.5rem"}})}),1),_c(VBtn,_vm._g({staticClass:"text-none align-self-end",attrs:{"elevation":"0","rounded":"","fab":"","x-small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.updateRoute(true, _vm.product)}}},on),[_c(VIcon,{attrs:{"medium":""}},[_vm._v(" mdi-chevron-right ")])],1)],1)])])]}}],null,true)})],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ModalProduct',{attrs:{"product":_vm.product},on:{"close-dialog":function($event){_vm.dialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }