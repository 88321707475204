import {THEME} from './theme';

export const ConfettiOptions = {
  particles: {
    color: {
      value: [THEME.primary, THEME.secondary, THEME.tertiary, '#ffffff'],
    },
    life: {
      duration: 10,
      count: 1,
    },
    wobble: {
      distance: {
        min: 0,
        max: 100,
      },
      enable: true,
    },
  },
  emitters: [
    {
      life: {
        duration: 5,
        count: 1,
      },
      position: {
        x: 20,
        y: 0,
      },
    },
    {
      life: {
        duration: 5,
        count: 1,
      },
      position: {
        x: 40,
        y: 0,
      },
    },
    {
      life: {
        duration: 5,
        count: 1,
      },
      position: {
        x: 80,
        y: 0,
      },
    },
  ],
  preset: 'confetti',
};
