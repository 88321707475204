





























import {Component, Prop, Vue} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';

import {Site} from '../interfaces';

@Component
export default class SelectorSite extends Vue {
  @Action('updateOpenSelector', {namespace: 'site'}) updateOpenSelector!: (
    isOpen: boolean
  ) => void;

  @Getter('slug', {namespace: 'site'}) slug!: string;

  @Getter('isOpenSelector', {namespace: 'site'}) isOpenSelector!: boolean;

  @Getter('siteList', {namespace: 'site'}) siteList!: Site[];

  @Prop() readonly isOpenFromHome!: boolean;

  mounted(): void {
    if (!this.isOpenFromHome) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.select as any).activateMenu();
    }
  }

  set selectedSite(site: Site) {
    if (site && site.slug) {
      this.updateOpenSelector(false);
      this.$router.push({
        name: (this.$route.name !== 'home' ? this.$route.name : 'homeSite') || 'homeSite',
        params: {slug: site.slug},
      });
    }
  }

  get selectedSite(): Site {
    return this.siteList.filter((site) => site.slug === this.slug)[0];
  }
}
