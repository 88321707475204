





















































































































import {ChartData, ChartOptions} from 'chart.js';
import {BarChart, ExtractComponentData} from 'vue-chart-3';
import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';

import {AffluenceParams, AffluenceResponse, AffluenceZone} from '@/site/interfaces';

import {setHoursAndMinutes} from '..';
import {DAYS, isCurrentStep, setLabel, setLabels, setPeople} from '../tools';

@Component({
  components: {BarChart},
})
export default class AffluenceModal extends Vue {
  @Action('getAffluence', {namespace: 'site'}) getAffluence!: (params: AffluenceParams) => void;

  @Getter('affluenceZones', {namespace: 'site'}) affluenceZones!: AffluenceZone[];

  @Getter('affluenceResponse', {namespace: 'site'}) affluenceResponse!: AffluenceResponse[];

  @Getter('customerId', {namespace: 'site'}) customerId!: string;

  @Ref() barChartRef!: ExtractComponentData<typeof BarChart>;

  days = DAYS;

  today = new Date();

  currentDay = this.today.getDay();

  isDirect = false;

  options: ChartOptions<'bar'> = {responsive: true, maintainAspectRatio: false};

  chartData: ChartData = {
    labels: undefined,
    datasets: [],
  };

  isRefreshed = false;

  currentStep: AffluenceResponse | null = null;

  mounted(): void {
    setInterval(() => {
      if (
        !((new Date().getMinutes() - 1) % 30) &&
        this.today.getDay() === this.currentDay &&
        !this.isRefreshed
      ) {
        this.getAffluenceResponse();
        if (this.barChartRef) {
          this.setChartData();
          // eslint-disable-next-line no-unused-expressions
          this.barChartRef.chartInstance?.update();
        }
      }
    }, 60000);
    setInterval(() => {
      this.today = new Date();
    }, 1000);

    if (
      this.affluenceZones.length &&
      this.affluenceZones[0].id &&
      !this.affluenceResponse.length
    ) {
      this.getAffluence({
        customerId: this.customerId,
        zone: this.affluenceZones[0].id,
        restaurant: this.affluenceZones[0].restaurant,
        start: setHoursAndMinutes(this.affluenceZones[0].start, this.today),
        end: setHoursAndMinutes(this.affluenceZones[0].end, this.today),
        type: 'in',
      });
    }
    if (this.affluenceResponse && this.affluenceResponse.length) {
      this.setOptions();
      this.setChartData();
      this.setMessage();
    }
  }

  set selectedDay(day: number) {
    this.currentDay = day;
    this.getAffluenceResponse();
  }

  get selectedDay(): number {
    return DAYS.filter((day) => day.weekDay === this.currentDay)[0].weekDay;
  }

  @Watch('$store.state.site.affluenceResponse')
  private updateGraph() {
    setTimeout(() => {
      this.isRefreshed = false;
    }, 120000);
    this.currentStep = null;
    if (this.affluenceResponse && this.affluenceResponse.length) {
      this.setOptions();
      this.setChartData();
      this.setMessage();
    }
  }

  getAffluenceResponse(): void {
    const selectedDay = new Date();
    if (selectedDay.getDay() !== this.currentDay) {
      selectedDay.setDate(selectedDay.getDate() - ((selectedDay.getDay() - this.currentDay) % 7));
    }
    this.getAffluence({
      customerId: this.customerId,
      zone: this.affluenceZones[0].id,
      restaurant: this.affluenceZones[0].restaurant,
      start: setHoursAndMinutes(this.affluenceZones[0].start, selectedDay),
      end: setHoursAndMinutes(this.affluenceZones[0].end, selectedDay),
      type: 'in',
    });
    this.isRefreshed = true;
  }

  setOptions(): void {
    this.options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {display: false, position: 'bottom'},
      },
      scales: {
        x: {
          grid: {
            display: true,
            drawOnChartArea: false,
            offset: false,
            tickLength: 10,
          },
          ticks: {
            display: true,
            padding: 10,
          },
        },
        y: {
          min: 0,
          max: this.affluenceResponse[0].max ? this.affluenceResponse[0].max + 5 : 100,
          grid: {
            display: false,
            drawTicks: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
          },
        },
      },
      onClick: (_, elements) => {
        if (elements.length && !!elements[0]) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const dataset = this.barChartRef.chartInstance?.data.datasets[0] as any;
          if (dataset) {
            const currentStep = this.affluenceResponse.findIndex((response) =>
              isCurrentStep(new Date(response.start), new Date(response.end))
            );
            dataset.backgroundColor = dataset.backgroundColor.map((__: never, index: number) => {
              if (index === elements[0].index)
                return index === currentStep
                  ? this.$vuetify.theme.themes.light.affluenceDirect?.toString()
                  : this.$vuetify.theme.themes.light.affluenceAvg?.toString();
              return index === currentStep ? '#FADADA' : '#EDEDF3';
            });
            if (this.barChartRef)
              // eslint-disable-next-line no-unused-expressions
              this.barChartRef.chartInstance?.update();
            this.setMessage(elements[0].index);
          }
        }
      },
    };
  }

  setChartData(): void {
    this.chartData = {
      labels: setLabels(this.affluenceResponse),
      datasets: [
        {
          label: 'Moyenne',
          data: setPeople(this.affluenceResponse),
          backgroundColor: this.affluenceResponse.map((response) =>
            isCurrentStep(new Date(response.start), new Date(response.end))
              ? this.$vuetify.theme.themes.light.affluenceDirect?.toString() || '#f8f8f8'
              : this.$vuetify.theme.themes.light.affluenceAvg?.toString() || '#00127B'
          ),
          barThickness: 25,
        },
      ],
    };
    if (this.barChartRef)
      // eslint-disable-next-line no-unused-expressions
      this.barChartRef.chartInstance?.update();
  }

  setMessage(index?: number): void {
    if (index || index === 0) {
      this.currentStep = this.affluenceResponse[index];
      this.isDirect =
        this.currentDay === this.today.getDay() &&
        isCurrentStep(new Date(this.currentStep.start), new Date(this.currentStep.end));
    } else {
      const filterSteps = this.affluenceResponse.filter((response) =>
        isCurrentStep(new Date(response.start), new Date(response.end))
      );
      if (filterSteps.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        this.currentStep = filterSteps[0];
        this.isDirect =
          this.currentDay === this.today.getDay() &&
          isCurrentStep(new Date(this.currentStep.start), new Date(this.currentStep.end));
      }
    }
  }

  formatLabel(): string {
    if (this.currentStep) return setLabel(this.currentStep.start);
    return 'Aucun palier sélectionné';
  }
}
