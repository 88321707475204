






import {Component, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import {Links} from '@/site/interfaces';

import Iframe from '../components/Iframe.vue';

@Component({components: {Iframe}})
export default class Badge extends Vue {
  @Getter('links', {namespace: 'site'}) links!: Links;
}
