




















import {Component, Vue, Watch} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import AppBar from '@/core/components/AppBar.vue';
import Footer from '@/core/components/Footer.vue';

@Component({
  components: {
    AppBar,
    Footer,
  },
})
export default class App extends Vue {
  @Getter('error', {namespace: 'site'}) error!: boolean;

  @Getter('name', {namespace: 'site'}) siteName!: string;

  @Getter('isOpenSelector', {namespace: 'site'}) isOpenSelector!: boolean;

  @Watch('$store.state.site.name')
  private updateTitle() {
    if (this.siteName) {
      document.title = this.siteName;
    }
  }

  @Watch('$store.state.site.error')
  private onError() {
    if (this.error && this.$route.name !== 'notFound') {
      document.title = 'Page introuvable';
      this.$router.replace('/non-trouve');
    }
  }

  mounted(): void {
    document.title = 'FJM';
  }
}
