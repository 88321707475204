import Vue from 'vue';
import VueRouter, {NavigationGuardNext, Route, RouteConfig} from 'vue-router';

import {articleRoutes} from '@/articles/router';
// import {bookingRoutes} from '@/bookings/router';
// import {feedbackRoutes} from '@/feedback/router';
import {menuRoutes} from '@/menu/router';

import {store} from './store';
import Badge from './views/Badge.vue';
import Cgu from './views/Cgu.vue';
import ClickAndCollect from './views/ClickAndCollect.vue';
import Home from './views/Home.vue';
import MentionsLegales from './views/MentionsLegales.vue';
import PageNotFound from './views/PageNotFound.vue';
import Wait from './views/Wait.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {title: 'Accueil'},
  },
  {
    path: '/wait',
    name: 'wait',
    component: Wait,
    meta: {title: 'Accueil'},
  },
  {
    path: '/non-trouve',
    name: 'notFound',
    component: PageNotFound,
    meta: {title: 'Page introuvable'},
  },
  {
    path: '/:slug',
    name: 'homeSite',
    component: Home,
    meta: {title: 'Accueil'},
  },
  {
    path: '/:slug/compte',
    name: 'badge',
    component: Badge,
    meta: {title: 'Compte'},
  },
  {
    path: '/:slug/clickandcollect',
    name: 'clickAndCollect',
    component: ClickAndCollect,
    meta: {title: 'Click And Collect'},
  },
  {
    path: '/:slug?/MentionsLegales',
    name: 'legalNotice',
    component: MentionsLegales,
    meta: {title: 'Mentions Légales'},
  },
  {
    path: '/:slug?/cgu',
    name: 'termsOfService',
    component: Cgu,
    meta: {title: 'CGU'},
  },
  ...menuRoutes,
  ...articleRoutes,
  {
    path: '/*',
    redirect: '/non-trouve',
  },
  // ...bookingRoutes,
  // ...feedbackRoutes,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext): void => {
  if (to.params.slug === 'pantin' || to.params.slug === 'boullay-les-troux') {
    next({name: 'notFound'});
  }

  if (to.name !== 'home' && !to.hash) window.scrollTo(0, 0);

  if (
    to.params.slug &&
    (!router.app.$store.getters['site/customerId'] ||
      router.app.$store.getters['site/slug'] !== to.params.slug)
  )
    store.dispatch('site/getSite', to.params.slug);

  if (router.app.$store.getters['site/siteList'].length === 0) store.dispatch('site/getSiteList');
  next();
});
export default router;
