import {Theme} from '../interfaces';

export const THEME: Theme = {
  primary: '#0051F0',
  secondary: '#000000',
  tertiary: '#ffff00',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
  anchor: '#004884',
  background: '#f8f8f8',
  affluenceDirect: '#E74545',
  affluenceAvg: '#669DF6',
};
